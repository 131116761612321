<template>
    <div class="site-form" :class="`mode-${mode}`" @submit.stop.prevent="handleSubmit">
        <slot name="title">
            <h1 v-if="formTitle" class="form-title">{{ formTitle }}</h1>
        </slot>
        <client-only>
            <form v-if="showForm" ref="form" :method="formMethod" novalidate>
                <p v-for="(paragraph, i) in preFormParagraphs" v-html="paragraph" :key="`pre-p-${i}`" />
                <div class="html-form-fields" v-if="htmlFormFields" v-html="htmlFormFields" />
                <slot v-else name="fields">
                    <div class="site-form__field-container" :ref="field.id" v-for="field in formFields" :key="field.id">
                        <div :class="`site-form__field --${field.type}`">
                            <Checkbox v-if="field.type === 'checkbox'" :id="field.id" :name="field.name"
                                :value="field.value" :checked="field.checked">{{ field.label }}</Checkbox>
                            <template v-else>
                                <label :for="field.name">{{ field.label }}</label>
                                <input :id="field.id" :name="field.name" :type="field.type" :value="field.value"
                                    :required="field.required" />
                            </template>
                        </div>
                    </div>
                </slot>
                <div class="site-form__field-container" :class="{ hidden: !showNonFieldErrors }">
                    <div class="site-form__field" ref="field-nonFieldErrors" />
                </div>
                <p v-for="(paragraph, i) in postFormParagraphs" v-html="paragraph" :key="`post-p-${i}`" />
                <script src="https://www.google.com/recaptcha/api.js" async defer></script>
                <div class="site-form__actions">
                    <slot name="actions">
                        <input type="submit" :value="submitButtonLabel" />
                    </slot>
                </div>
                <div class="site-form__sub-actions" v-if="$slots.subActions">
                    <slot name="subActions" />
                </div>
            </form>
            <div v-else-if="htmlType === 'submitResponse'" v-html="submitReponseHTML" />
        </client-only>
        <div class="site-form__footer" v-if="$slots.footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Checkbox from '@module/Checkbox.vue';
import AutocompleteDropdown from './AutocompleteDropdown.vue';
import { titleize } from '@component/utils/string';

const WAF_CLASSNAME_MAP = {
    'waf--field-container--error': '--error',
    'waf--field-container': 'site-form__field-container',
    'waf--field-error': 'site-form__error-message',
    'waf--field': 'site-form__field',
    'waf--form': 'site-form',
};
export default {
    props: {
        fields: {
            type: Array,
            default: () => [],
        },
        initialValues: {
            type: Object,
            default: () => ({}),
        },
        errors: {
            type: Object,
        },
        formUrl: {
            type: String,
        },
        mode: {
            type: String,
            default: 'default',
        },
        hideUndefinedFields: {
            type: Boolean,
            default: false,
        },
        autoFocus: {
            type: Boolean,
            default: false,
        },
        formMethodOverride: {
            type: String,
        },
        submitLabel: {
            type: String,
            default: 'Submit',
        },
    },
    data() {
        return {
            _processing: false,
            htmlFormFields: '',
            submitButtonLabel: this.submitLabel,
            formMethod: 'POST',
            htmlType: '', // form or submitResponse
            formTitle: '',
            formErrors: [],
            preFormParagraphs: [],
            postFormParagraphs: [],
            displayedErrors: [],
            customFormFields: [],
            submitReponseHTML: '',
            showNonFieldErrors: false,
            formMediaElems: [],
        };
    },
    computed: {
        isProcessing() {
            return this._processing;
        },
        classnames: () => ({
            fieldContainer: 'site-form__field-container',
            fieldBlock: 'site-form__field',
            errorState: '--error',
            errorMessages: 'site-form__error-messages',
            errorMessage: 'site-form__error-message',
        }),
        showForm() {
            if (this.formUrl && this.formUrl.length) {
                return this.htmlType === 'form';
            } else {
                return this.formFields.length;
            }
        },
        formFields() {
            return this.fields?.map((field, index) => {
                const id = `field-${index}-${field.name.replace(/(\[|\])/g, '')}`;
                return {
                    id,
                    ...field,
                    label: field.label || this.labelize(field.name),
                    type: field.type || 'text',
                };
            });
        },
        renderedFields() {
            return Array.from(this.$el.querySelectorAll('.site-form__field-container'))
                .map((container) => {
                    const input = container.querySelector('[name]');
                    if (!input) return null;

                    const name = input.name;
                    return {
                        name,
                        container,
                        input,
                    };
                })
                .filter(Boolean);
        },
        form() {
            return this.$refs.form;
        },
        fieldNameMap() {
            const nameMap = {};
            this.formFields.forEach((field) => {
                nameMap[field.name] = field.name;
            });
            return nameMap;
        },
    },
    watch: {
        errors(errors) {
            this.setErrors(errors);
        },
    },
    created() {
        if (this.formUrl) {
            this.fetchAndRenderForm(this.formUrl);
        }
    },
    methods: {
        labelize(fieldName) {
            return titleize(fieldName);
        },
        serializeFormData(formData) {
            const variables = {};
            for (const entry of formData.entries()) {
                let [key, value] = entry;
                let isArray = key.includes('[]');
                key = key.replace('[]', '');
                if (isArray) {
                    variables[key] = variables[key] || [];
                    variables[key].push(value);
                } else {
                    variables[key] = value;
                }
            }
            return variables;
        },
        handleSubmit() {
            if (this.isProcessing) return;
            const formData = new FormData(this.$refs.form);
            localStorage.setItem('formData', JSON.stringify(this.serializeFormData(formData)));

            if (this.isValid()) {
                if (this.formUrl && !this.$listeners.submit) {
                    this.submitForm(this.formUrl, this.$refs.form);
                } else {
                    this.$emit('submit', this.serializeFormData(formData));
                }
            }
        },
        isValid() {
            let errors = [];
            let isValid = true;
            this.formFields.forEach((field) => {
                const fieldRef = this.$refs[field.id];
                if (!fieldRef || fieldRef.length === 0) {
                    return;
                }
                const fieldEl = fieldRef[0];
                const fieldInput = fieldEl.querySelector(`[name]`);
                let fieldIsValid = true;

                const { value, type } = fieldInput;
                const isRequired = fieldInput.hasAttribute('required');

                // check that required fields have a value
                fieldIsValid = !isRequired || (isRequired && !!value);

                // required checkboxes must be `checked`, which is not reflected in
                // `fieldInput.value`
                if (type === 'checkbox' && isRequired) {
                    fieldIsValid = !!fieldInput.checked;
                }

                if (!fieldIsValid) {
                    errors.push({ fieldEl, messages: ['This field is required'] });
                    isValid = false;
                }
            });
            this.setErrors(errors);
            return isValid;
        },
        getFieldConfig(fieldName) {
            let fieldConfig = {
                id: `field-${fieldName}`,
                options: [],
                value: null,
            };
            if (fieldName === 'nonFieldErrors') {
                fieldConfig = { id: 'field-nonFieldErrors' };
            } else {
                fieldConfig =
                    this.formFields.find((formField) => {
                        return [formField.name, formField.customFormName].includes(fieldName);
                    }) || fieldConfig;
            }
            return fieldConfig;
        },
        setErrors(errors) {
            this.showNonFieldErrors = false;
            if (!(errors instanceof Object)) {
                return console.warn('Errors needs to be an Object or an Array');
            }
            if (!(errors instanceof Array)) {
                errors = Object.entries(errors)
                    .filter(([fieldName, messages]) => messages && this.getFieldConfig(fieldName))
                    .map(([fieldName, messages]) => {
                        const fieldConfig = this.getFieldConfig(fieldName);
                        this.showNonFieldErrors = this.showNonFieldErrors || fieldName === 'nonFieldErrors';
                        let fieldEl =
                            this.$refs[fieldConfig.id] || this.$refs.form.querySelector(`[name=${fieldName}]`);
                        if (fieldEl) {
                            fieldEl = fieldEl instanceof Array ? fieldEl[0] : fieldEl;
                            return {
                                fieldName,
                                fieldEl,
                                messages: messages.map((message) => message.message || message),
                            };
                        }
                    });
            }
            this.formErrors = errors.map((error) => {
                return {
                    fieldEl: error.fieldEl || this.$refs.form.querySelector(`[name=${error.fieldName}]`),
                    fieldName: error.fieldName,
                    messages: error.messages,
                };
            });
            this.displayErrors();
        },
        displayErrors() {
            const { classnames } = this;
            this.displayedErrors.forEach((displayedError) => {
                displayedError.fieldContainer.classList.remove(classnames.errorState);
                displayedError.messages.remove();
            });
            this.displayedErrors = [];
            this.formErrors.forEach((error) => {
                const messages = document.createElement('div');
                const fieldContainer = error.fieldEl.closest(`.${classnames.fieldContainer}`);
                messages.classList.add(classnames.errorMessages);
                error.messages.forEach((errorMessage) => {
                    const message = document.createElement('p');
                    message.classList.add(classnames.errorMessage);
                    message.append(errorMessage);
                    messages.append(message);
                });
                fieldContainer.classList.add(classnames.errorState);
                fieldContainer.append(messages);
                this.displayedErrors.push({
                    fieldEl: error.fieldEl,
                    fieldContainer,
                    messages,
                });
            });
        },
        async fetchAndRenderForm(endpoint) {
            if (!process.client) {
                return;
            }
            const fetchResponse = await this.fetchForm(endpoint);
            this.renderForm(fetchResponse);
            this.focusFirstField();
        },

        async renderForm(fetchResponse) {
            const htmlFormFields = await fetchResponse.text();
            this.parseFetchHTML(htmlFormFields);
            await this.$nextTick();
            this.processFetchedForm();
            this.$emit('rendered', this.$refs.form);
            // This is added to allow for the event to bubble up the DOM tree,
            // the standard $emit seems to only emit to the immediate parent.
            this.$el.dispatchEvent(new CustomEvent('form-rendered', { bubbles: true }));
        },

        async renderResponse(fetchResponse) {
            const responseHTML = await fetchResponse.text();
            this.parseFetchHTML(responseHTML);
        },

        async submitForm(endpoint, form, { handleResponse = true } = {}) {
            this.setProcessing(true);
            form = form || this.$refs.form;
            form.method = 'post';
            const fetchResponse = await this.fetchForm(endpoint, form);
            this.$emit('submit-complete', fetchResponse);

            if (handleResponse) {
                if (fetchResponse.redirected) {
                    this.renderResponse(fetchResponse);
                    localStorage.removeItem('formData');
                } else {
                    await this.renderForm(fetchResponse);
                    if (window) {
                        const sitekey = document.querySelector('#id_wagtailcaptcha').dataset.sitekey;

                        window.grecaptcha.render('id_wagtailcaptcha', {
                            sitekey: sitekey,
                        });
                    }
                }
            }
            this.setProcessing(false);
        },
        async fetchForm(endpoint, form) {
            if (!endpoint) {
                return;
            }
            const formData = form ? new FormData(form) : {};
            const headers = form
                ? {
                    'X-CSRFToken': formData.get('csrfmiddlewaretoken'),
                    'X-HTTP-Method-Override': this.formMethod,
                }
                : {};

            if (this.authToken) {
                headers['Authorization'] = `JWT ${this.authToken}`;
            }

            const fetchConfig = form
                ? {
                    method: form.method,
                    body: formData,
                    headers,
                }
                : {};
            const fetchResponse = await fetch(endpoint, fetchConfig);
            return fetchResponse;
        },

        parseFetchHTML(fetchHTML) {
            let formTitle,
                form,
                preFormParagraphs = [],
                postFormParagraphs = [];
            const tmpDiv = document.createElement('div');
            tmpDiv.innerHTML = fetchHTML;
            const formWrapper = tmpDiv.querySelector('.form-wrapper');
            Array.from(formWrapper.children).forEach((childEl) => {
                switch (childEl.tagName) {
                    case 'P':
                        if (form) {
                            postFormParagraphs.push(childEl.innerHTML);
                        } else {
                            preFormParagraphs.push(childEl.innerHTML);
                        }
                        break;
                    case 'FORM':
                        form = childEl;
                        break;
                    case 'H1':
                        formTitle = childEl;
                        break;
                }
            });

            if (formTitle) {
                this.formTitle = formTitle.innerText;
            }
            this.preFormParagraphs = preFormParagraphs;
            this.postFormParagraphs = postFormParagraphs;

            if (form) {
                this.htmlType = 'form';
                this.parseFormHTML(form, tmpDiv);
            } else {
                this.htmlType = 'submitResponse';
                this.parseSubmitResponseHTML(tmpDiv);
            }
        },

        parseFormHTML(form, tmpDiv) {
            tmpDiv.querySelectorAll('.form-media').forEach((formMedia) => {
                formMedia.querySelectorAll('script').forEach((script) => {
                    this.injectFormScript(script);
                    script.remove();
                });
                formMedia.remove();
            });
            const scripts = tmpDiv.querySelectorAll('script');
            scripts.forEach((script) => {
                let conditions = script.innerText.match(/window\.wafFormConditions\s=\s(.*?);/);
                if (conditions) {
                    this.addFormConditions(JSON.parse(conditions[1]));
                }
                script.remove();
            });

            const submitButton = form.querySelector('[type="submit"]');
            this.submitButtonLabel = submitButton.value;
            submitButton.remove();

            const honeypotFields = tmpDiv.querySelector('.honeypot-fields');
            if (honeypotFields) {
                form.appendChild(honeypotFields);
                honeypotFields.classList.add('hidden');
            }

            form.querySelectorAll('a[href]').forEach((link) => {
                link.setAttribute('target', '_blank');
            });

            let htmlFormFields = form.innerHTML;
            this.formMethod = this.formMethodOverride || form.method;
            this.htmlFormFields = htmlFormFields;
        },

        parseSubmitResponseHTML(tmpDiv) {
            this.submitReponseHTML = tmpDiv.querySelector('.form-wrapper').innerHTML;
        },

        addFormConditions(formConditions) {
            window.wafFormConditions = window.wafFormConditions || [];
            formConditions.forEach((formCondition) => {
                const existingCondition = window.wafFormConditions.find(
                    (existingCondition) =>
                        existingCondition.field_name === formCondition.field_name &&
                        existingCondition.action === formCondition.action,
                );
                if (existingCondition) {
                    existingCondition.conditions = existingCondition.conditions.concat(formCondition.conditions);
                } else {
                    window.wafFormConditions.push(formCondition);
                }
            });
        },

        processFetchedForm() {
            if (this.htmlType !== 'form' || !this.$refs.form) {
                return;
            }
            Object.entries(WAF_CLASSNAME_MAP).forEach(([waf_classname, site_classname]) => {
                this.$refs.form.querySelectorAll(`.${waf_classname}`).forEach((wafElem) => {
                    wafElem.classList.replace(waf_classname, site_classname);
                });
            });
            this.$refs.form.querySelectorAll('[name]:not([type="hidden"])').forEach((fieldInput, idx) => {
                const { name, tagName, type } = fieldInput;
                const fieldConfig = this.getFieldConfig(name);
                const initialValue = this.initialValues[name];
                const fieldContainer = fieldInput.closest(`.${this.classnames.fieldContainer}`);
                const fieldType = tagName === 'SELECT' ? 'select' : type;

                if (!fieldContainer) {
                    return;
                }
                fieldContainer.classList.add(`input-type--${fieldType}`);
                if (initialValue) {
                    fieldInput.value = initialValue;
                }
                if (fieldConfig) {
                    this.$refs[fieldConfig.id] = [fieldContainer];
                    fieldInput.value = fieldConfig.value || fieldInput.value || '';
                    fieldContainer.classList.remove('hidden');

                    if (fieldType === 'checkbox') {
                        fieldInput.checked = Boolean(fieldConfig.value) || fieldInput.hasAttribute('checked');
                    }
                } else if (this.hideUndefinedFields) {
                    fieldContainer.classList.add('hidden');
                }
                if (fieldInput.hasAttribute('required')) {
                    fieldContainer.classList.add('required');
                }
                if (fieldInput.hasAttribute('data-autocomplete')) {
                    this.setupAutocomplete(fieldInput, fieldConfig, idx);
                }
            });
            if (this.htmlFormFields.length) {
                window.waf.initForm(this.$refs.form);
            }
        },

        setupAutocomplete(input, fieldConfig, idx) {
            const name = input.getAttribute('name');
            const label = this.labelize(name);
            let emptyLabel = null;
            const options = window.el_autocompletes?.[name]?.options || fieldConfig.options;
            const formattedOptions = options.reduce((result, option) => {
                if (option.value) {
                    result.push({ id: option.value, name: option.label });
                } else {
                    emptyLabel = option.label;
                }
                return result;
            }, []);
            const mountNode = document.createElement('div');
            const initialValue = this.initialValues[name] || fieldConfig.value;
            mountNode.id = `mount-node-${idx}`;

            input.parentNode.appendChild(mountNode);
            input.classList.add('hidden');
            input.parentNode.parentNode.classList.remove('input-type--select');
            input.parentNode.parentNode.classList.add('input-type--autocomplete');

            let searchable = Vue.extend(AutocompleteDropdown);
            new searchable({
                propsData: {
                    fieldInfo: {
                        name,
                        label,
                        options: formattedOptions,
                        emptyLabel,
                        value: initialValue || input.value,
                        attrs: input.dataset,
                    },
                },
                created() {
                    this.$on(['selected'], (item) => {
                        input.innerHTML = item.id
                            ? `<option value="${item.id}" selected="selected">${item.name}</option>`
                            : '';
                        input.value = item.id;
                        input.dispatchEvent(new Event('change'));
                    });
                },
            }).$mount(`#mount-node-${idx}`);
        },

        focusFirstField() {
            if (!this.autoFocus) {
                return;
            }
            this.$refs.form.querySelector('[name]:not([type="hidden"])').focus();
        },

        injectFormScript(script) {
            const formScript = document.createElement('script');
            if (!script.src) {
                formScript.innerText = script.innerText;
            } else {
                formScript.src = script.src;
                formScript.async = script.async;
                formScript.defer = script.defer;
            }
            document.body.appendChild(formScript);
            this.formMediaElems.push(formScript);
        },
        setProcessing(_processing) {
            this.isProcessing = _processing;
            this.$emit('processing', _processing);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.focusFirstField();
        });
    },

    destroyed() {
        this.formMediaElems.forEach((elem) => {
            elem.remove();
        });
        localStorage.removeItem('formData');
    },

    components: {
        Checkbox,
    },
};
</script>
